import React from 'react';
import { Container, Form, Button } from 'react-bootstrap';
import '../../App.css';

export default function index() {
  return (
    <Container className="my-5 col-md-6">
      <Container className='title-container'>
        <h2 className="text-center mb-4">Contact Us</h2>
      </Container>
      
      <Form>
        <Form.Group controlId="formName" className="mb-3">
          <Form.Control type="text" placeholder="Enter your name" />
        </Form.Group>

        <Form.Group controlId="formEmail" className="mb-3">
          <Form.Control type="email" placeholder="Enter your email" />
        </Form.Group>

        <Form.Group controlId="formSubject" className="mb-3">
          <Form.Control type="text" placeholder="Enter subject" />
        </Form.Group>

        <Form.Group controlId="formMessage" className="mb-3">
          <Form.Control as="textarea" rows={4} placeholder="Enter your message" />
        </Form.Group>

        <Button variant="primary" type="submit">
          Submit
        </Button>
      </Form>
    </Container>
  );
}
