import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBullseye, faEye, faCode, faCloud } from '@fortawesome/free-solid-svg-icons';
import '../../App.css';


export default function index() {
  return (
    <Container className="text-center my-5 col-md-8 about-us-section">
      <Container className='title-container'>
        <h2 className="text-center mb-4">Our Services</h2>
      </Container>
    <p className="my-4">
    At World Web Connect, we offer a wide range of services designed to meet the diverse needs of our clients. From full-stack development to cloud computing, we provide comprehensive training programs that prepare developers for the tech industry. Additionally, we deliver bespoke application development and IT consulting services that help businesses achieve their goals through innovative and effective technology solutions.
    </p>
    <Container className='py-3'>
      <h3 className="my-5">What We Do</h3>
    </Container>
    <Row className="my-5 d-flex justify-content-around">
      <Col xs={10} sm={8} md={5} className="mb-4 service-col">
        <FontAwesomeIcon icon={faCode} size="3x" className="mb-3 icon" />
        <h3>Full Stack Development</h3>
        <p>
          We train developers in full stack development, ensuring they are proficient in both frontend and backend technologies.
        </p>
      </Col>
      <Col xs={10} sm={8} md={5} className="mb-4 service-col">
        <FontAwesomeIcon icon={faCloud} size="3x" className="mb-3 icon" />
        <h3>Cloud Computing</h3>
        <p>
          Our training includes cloud computing skills, preparing developers for the modern tech infrastructure.
        </p>
      </Col>
    </Row>
  </Container>
  )
}
