import React, { useState, useEffect } from 'react';
import { Navbar, Nav, Container, Button } from 'react-bootstrap';
import { Link as ScrollLink, Element, Events, scrollSpy } from 'react-scroll';
import 'bootstrap/dist/css/bootstrap.min.css';
import Home from './pages/Home';
import About from './pages/About';
import Services from './pages/Services';
import Training from './pages/Training';
import Projects from './pages/Projects';
import Contact from './pages/Contact';
import Header from './components/Header';
import Footer from './components/Footer';
import './App.css';

const App = () => {
  const [activeSection, setActiveSection] = useState('home');

  useEffect(() => {
    Events.scrollEvent.register('begin', function () {
      console.log('begin', arguments);
    });

    Events.scrollEvent.register('end', function () {
      console.log('end', arguments);
    });

    scrollSpy.update();

    return () => {
      Events.scrollEvent.remove('begin');
      Events.scrollEvent.remove('end');
    };
  }, []);

  const handleSetActive = (section) => {
    setActiveSection(section);
  };

  return (
    <div>
      <Navbar
        bg="light"
        variant="light"
        expand="lg"
        className={`sticky-top px-3 ${activeSection}`}
      >
        <Container fluid>
          <Navbar.Brand href="/">
            <img src={require('./assets/img/400dpiLogo.png')} width={150} alt='WWC Logo' />
          </Navbar.Brand>
          <Navbar.Toggle aria-controls="basic-navbar-nav" />
          <Navbar.Collapse id="basic-navbar-nav">
            <Nav className="ms-auto">
              <Nav.Link>
                <ScrollLink
                  to="home"
                  smooth={true}
                  duration={500}
                  offset={-120}
                  onSetActive={() => handleSetActive('home')}
                >
                  Home
                </ScrollLink>
              </Nav.Link>
              <Nav.Link>
                <ScrollLink
                  to="about"
                  smooth={true}
                  duration={500}
                  offset={-120}
                  onSetActive={() => handleSetActive('about')}
                >
                  About Us
                </ScrollLink>
              </Nav.Link>
              <Nav.Link>
                <ScrollLink
                  to="services"
                  smooth={true}
                  duration={500}
                  offset={-120}
                  onSetActive={() => handleSetActive('services')}
                >
                  Services
                </ScrollLink>
              </Nav.Link>
              {/* <Nav.Link>
                <ScrollLink
                  to="training"
                  smooth={true}
                  duration={500}
                  onSetActive={() => handleSetActive('training')}
                >
                  Training
                </ScrollLink>
              </Nav.Link>
              <Nav.Link>
                <ScrollLink
                  to="projects"
                  smooth={true}
                  duration={500}
                  onSetActive={() => handleSetActive('projects')}
                >
                  Projects
                </ScrollLink>
              </Nav.Link> */}
              <Nav.Link>
                <ScrollLink
                  to="contact"
                  smooth={true}
                  duration={500}
                  offset={-120}
                  onSetActive={() => handleSetActive('contact')}
                >
                  Contact Us
                </ScrollLink>
              </Nav.Link>
            </Nav>
          </Navbar.Collapse>
        </Container>
      </Navbar>

      <Element name="home" className="section p-0 m-0">
        <section id="home" className="text-center text-white hero-section">
          <Container className='col-md-12'>
            <Home/>
          </Container>
        </section>
      </Element>

      <Element name="about" className="section">
        <section id="about">
          <About/>
        </section>
      </Element>

      <Element name="services" className="section">
        <section id="services">
          <Services/>
        </section>
      </Element>

      {/* <Element name="training" className="section">
        <section id="training">
          <Container>
            <h2>Training</h2>
            <p>Our comprehensive training programs are designed to take you from no experience to a professional level. We provide hands-on training and mentorship to ensure you succeed in your IT career.</p>
          </Container>
        </section>
      </Element>

      <Element name="projects" className="section">
        <section id="projects">
          <Container>
            <h2>Projects</h2>
            <p>We have successfully delivered numerous projects across various industries. Our portfolio showcases our ability to deliver innovative and effective solutions.</p>
          </Container>
        </section>
      </Element> */}

      <Element name="contact" className="section">
        <section id="contact">
          <Contact/>
        </section>
      </Element>

      <footer className="bg-dark text-white mt-5 p-4 text-center">
        <Container>
          <p>&copy; {new Date().getFullYear()} World Web Connect. All rights reserved.</p>
        </Container>
      </footer>
    </div>
  );
};

export default App;
