import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBullseye, faEye, faCode, faCloud } from '@fortawesome/free-solid-svg-icons';
import '../../App.css';

export default function About() {
  return (
    <Container className="text-center my-5 col-md-8 mb-3 about-us-section">
      <h2>About Us</h2>
      <Container className='mb-5'>
        <p className="my-4">
        World Web Connect is a dynamic startup dedicated to bridging the gap between aspiring developers and the tech industry. Our core mission is to train developers, equipping them with the essential skills in full-stack development and cloud computing. We prepare our trainees to excel in the competitive tech landscape, ensuring they are industry-ready.
        </p>
        <p className="my-4">
          Beyond training, we also specialize in developing cutting-edge applications tailored to meet the specific needs of our clients. Our expertise enables us to recommend the best technological solutions that drive business success and innovation. At World Web Connect, we are committed to fostering growth and delivering excellence through our comprehensive training programs and bespoke application development services.
        </p>
      </Container>
      
      <Row className="my-5 d-flex justify-content-around">
        <Col xs={10} sm={8} md={5} className="mb-4 service-col">
          <FontAwesomeIcon icon={faBullseye} size="3x" className="mb-3 icon" />
          <h3>Our Mission</h3>
          <p>
            To provide innovative and effective IT solutions that enable our clients to achieve their business objectives and thrive in a competitive market.
          </p>
        </Col>
        <Col xs={10} sm={8} md={5} className="mb-4 service-col">
          <FontAwesomeIcon icon={faEye} size="3x" className="mb-3 icon" />
          <h3>Our Vision</h3>
          <p>
            To be the premier IT consulting firm known for transforming businesses through technology, fostering growth, and driving success for our clients and communities.
          </p>
        </Col>
      </Row>
    </Container>
  );
}
