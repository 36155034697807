import React from 'react';
import '../../App.css';

import { Container, Button } from 'react-bootstrap';

export default function index() {
  return (
    <Container>
      <Container className='flex-start hero-text-styles'>
        <h1>Welcome <br/>to<br/> World Web Connect</h1>
        <p>Your one-stop solution for IT consulting and software development.</p>
        <Button className="learn-more-cta" size="lg" href="#about">Learn More</Button>  
      </Container>
    </Container>
  )
}
